form label {
  min-width: 150px;
  color: black;
  float: left;
  display: flex;
}
/* Remove the arrow (spinner) in number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: Remove any remaining margin */
}
